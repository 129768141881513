/**
 * Created by jerry on 2020/04/20.
 * 产品价格调整api
 */
import * as API from '@/api/index'

export default {
    // 获取产品价格调整列表
    getProductPriceAdjustment: params => {
        return API.POST('api/productPriceAdjustment/list',params)
    },
    // 新增产品价格调整
    createProductPriceAdjustment: params => {
        return API.POST('api/productPriceAdjustment/create',params)
    },
    // 更新产品价格调整
    updateProductPriceAdjustment: params => {
        return API.POST('api/productPriceAdjustment/update',params)
    },
    // 删除产品价格调整
    deleteProductPriceAdjustment: params => {
        return API.POST('api/productPriceAdjustment/delete',params)
    }
}
